import TextBox from "../../components/TextBox";
import { Container, Stack } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import BlogPost from "../Blog/BlogPost";
import Post from "../../components/Post";

const FULL_TEXT = `
# Courses Taken
*Here is a list of courses I've taken so far in NUS sorted on each subsection by ascending level of complexity. Do read the note at the bottom first!*

## Current Courses
|Course Code|Topic|
|--|--|
|ES2066|Communicating in the Information Age|
|[ENV3104](https://nusmods.com/courses/ENV3104/socio-ecological-assessment-of-environmental-impacts)|**Socio-Ecological Assessment of Environmental Impacts**|
|[CS3211](https://nusmods.com/courses/CS3211/parallel-and-concurrent-programming)|**Parallel and Concurrent Programming**|
|[CS4231](https://nusmods.com/courses/CS4231/parallel-and-distributed-algorithms)|**Parallel and Distributed Algorithms**|

## Computer Science and its Math Courses
|Course Code|Topic|
|--|--|
|[CS1101S](https://nusmods.com/courses/CS1101S/programming-methodology)|*Programming Methodology* ⭐|
|[CS1231S](https://nusmods.com/courses/CS1231S/discrete-structures)|Discrete Structures|
|[CS2030S](https://nusmods.com/courses/CS2030S/programming-methodology-ii) | *Programming Methodology II* ⭐|
|[CS2040S](https://nusmods.com/courses/CS2040S/data-structures-and-algorithms) | Data Structures and Algorithms |
|[CS2100](https://nusmods.com/courses/CS2100/computer-organisation)|*Computer Organization*⭐|
|[CS2102](https://nusmods.com/courses/CS2102/database-systems)|**Database Systems**|
|[CS2103T](https://nusmods.com/courses/CS2103T/software-engineering)|Software Engineering ⭐|
|[CS2105](https://nusmods.com/courses/CS2105/introduction-to-computer-networks)|Computer Networks|
|[CS2109S](https://nusmods.com/courses/CS2109S/introduction-to-ai-and-machine-learning)|**AI and Machine Learning**|
|[CS2106](https://nusmods.com/courses/CS2106/introduction-to-operating-systems)|Operating Systems|
|[CS3210](https://nusmods.com/courses/CS3210/parallel-computing)|**Parallel Computing** ⭐|
|[CS3103](https://nusmods.com/courses/CS3103/computer-networks-practice)|**Computer Networks Practice**⭐⭐|
|[CS3230](https://nusmods.com/courses/CS3230/design-and-analysis-of-algorithms)|**Design and Analysis of Algorithm** ⭐|
|[CS3223](https://nusmods.com/courses/CS3223/database-systems-implementation)|**Database Systems Implementation**⭐|
|[CS4211](https://nusmods.com/courses/CS4211/formal-methods-for-software-engineering)|**Formal Methods for Software Engineering**|
|[CS4221](https://nusmods.com/courses/CS4221/database-applications-design-and-tuning)|**Database Applications Design and Tuning**⭐⭐|
|[CS4224](https://nusmods.com/courses/CS4224/distributed-databases)|**Distributed Databases**⭐|
|[CS4225](https://nusmods.com/courses/CS4225/big-data-systems-for-data-science)|**Big Data Systems for Data Science**⭐|
|[CS4234](https://nusmods.com/courses/CS4234/optimisation-algorithms)|**Optimisation Algorithms**|

## Math, Applied Math, and other STEM Courses
|Course Code|Topic|
|--|--|
|MA1521|Calculus for Computing|
|[QF1100](https://nusmods.com/courses/QF1100/introduction-to-quantitative-finance)|**Introduction to Quantitative Finance** ⭐|
|LSM1301|General Biology ⭐|
|MA2001|Linear Algebra I|
|ST2334|Probability and Statistics|
## Social Science
|Course Code|Topic|
|--|--|
|GEC1030|**Metropolis: The City in World's History**⭐⭐|
|GESS1014|Islam and Contemporary Malay Society ⭐|
|[GEN2004](https://nusmods.com/courses/GEN2004/green-communities-in-action-climate-species-crisis)|Green Communities in Action: Climate, Species, Crisis⭐⭐|
|[RE1701](https://nusmods.com/courses/RE1701/urban-land-use-and-development)|Urban Land Use and Development|
|[RE1706](https://nusmods.com/courses/RE1706/design-and-construction)|Design and Construction|
|[GE3204](https://nusmods.com/courses/GE3204/cities-and-regions-planning-for-change)|**Cities and Regions: Planning for Change**⭐⭐|
|[GE3236](https://nusmods.com/courses/GE3236/transport-and-communications)|**Transport and Communications** ⭐⭐|
|||

## non-relevant
A list of courses that I deemed irrelevant but I have taken anyway
|Course Code| Topic |
|--|--|
|IS1103|Ethics in Computing|
|GEA1000|Quantitative Reasoning with Data|
|[CS2101](https://nusmods.com/courses/CS2101/effective-communication-for-computing-professionals)|Effective Communication for Computing Professionals ⭐|


## Note
- Courses written in **bold** means they point out specific areas of expertise
- Courses written in *italic* means I have taught or am teaching the course
- Courses labelled ⭐ are courses where I did well and I am particularly confident in
- Courses labelled ⭐⭐ are courses where I did very well and I am particularly confident in
- Some courses have [links](/) because I think the description might be quite important
- These labels, however, might not necessarily reflect my grades :)
`;

export default function AcademicsMain() {
  return (
    <>
      <Helmet>
        <title>Academics</title>
        <meta
          name="description"
          content="A list of courses I've taken so far in NUS sorted on each subsection by ascending level of complexity."
        />
      </Helmet>
      <Stack spacing={8}>
        <Post post={{body: FULL_TEXT}} />
      </Stack>
    </>
  );
}
